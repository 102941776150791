@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
.info {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: 75vh;
  max-width: 900px;
  max-height: 800px;
  padding: 10px 6px 10px 20px;
  @media screen and (max-width: $breakpoint-mobile-landscape-max) and (orientation: portrait) {
    padding-left: 10px;
    padding-right: 0;
  }
  .header {
    padding: 0;
    margin: 0;
    list-style: none;
    color: red;
    @media screen and (max-width: $breakpoint-mobile-landscape-max) {
      flex-wrap: wrap;
    }
    .item {
      font-size: 17px;
      font-weight: bold;
      margin: 5px 10px;
      color: #616160;
      cursor: pointer;
      transition: color 0.3s ease;
      &:global(.react-tabs__tab--selected) {
        color: #f6e87e;
        cursor: default;
        outline: none;
      }
      &:hover {
        color: #fff;
      }
    }
  }
  .container {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #010101;
    }
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #f6e87e;
    }
  }
}
.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
}
.title {
  color: #ff9d00;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
  @media screen and (max-width: $breakpoint-mobile-landscape-max) {
    font-size: 15px;
  }
  &:nth-child(n + 2) {
    margin-top: 70px;
  }
}
.paytablefreeSpinInfo {
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
}
section {
  padding-right: 15px;
}
.p {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  @media screen and (max-width: $breakpoint-mobile-landscape-max) {
    font-size: 15px;
  }
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  span {
    color: #f6e87e;
  }
}
.p_1 {
  font-size: 20px;
  color: #fff;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 1px;
  white-space: nowrap;
  @media screen and (max-width: $breakpoint-mobile-landscape-max) {
    font-size: 15px;
  }
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  span {
    color: #f6e87e;
  }
}
.p_2 {
  font-size: 20px;
  color: #fff;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 1px;
  @media screen and (max-width: $breakpoint-mobile-landscape-max) {
    font-size: 15px;
  }
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  span {
    color: #f6e87e;
  }
}
ul {
  list-style-type: none;
  @media screen and (max-width: $breakpoint-mobile-landscape-max) {
    padding-left: 10px;
  }
}
li {
  display: inline-block;
}
.color {
  color: #f6e87e;
}
.special-combo-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .special-combo-item {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-bottom: 25px;
    .combo {
      display: flex;
      img {
        width: 33.3333%;
      }
    }
    p {
      margin: 0;
    }
  }
}
.paytableFreeSpinInfo {
  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    font-weight: 700;
    color: #fff;
    font-size: 20px;
    @media screen and (max-width: $breakpoint-mobile-landscape-max) {
      font-size: 15px;
    }
  }
}
.paytable-list {
  flex-wrap: wrap;
  padding-top: 50px;
  &__item {
    .img {
      text-align: center;
      img {
        @media screen and (max-width: $breakpoint-mobile-portrait-max) and (orientation: portrait) {
          width: 45%;
        }
        width: 35%;
      }
    }
    .content {
      display: flex;
      justify-content: center;
      margin-top: -20px;
    }
  }
}
.gameRules {
  display: block;
  width: 80%;
  margin: 0 auto;
  @media screen and (max-width: $breakpoint-mobile-portrait-max) and (orientation: portrait) {
    width: 100%;
  }
}
.payLines {
  img {
    display: block;
    max-width: 80%;
    margin: 0 auto;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    @media screen and (max-width: $breakpoint-mobile-portrait-max) and (orientation: portrait) {
      max-width: 100%;
    }
  }
}
.feature {
  padding-bottom: 2em;
  .figcontainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .fig {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em 20px;
    p.caption {
      font-weight: 700;
      font-size: 20px;
      color: #fff;
      margin: 0;
    }
  }
}
.paytable-list__title {
  text-transform: capitalize;
}
.buttonBehaviors {
  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    font-weight: 700;
    color: #fff;
    img {
      width: 50px;
      margin-right: 15px;
      user-select: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;
      &.bigger {
        width: 100px;
      }
    }
    p {
      text-align: center;
    }
  }
}
